import React from "react"
import Outbound from "./outbound"
import OpenHub from "./open_hub"
import Experiences from "./experiences"

// TODO: should probably add technical experience
const OpenSource = () => (
  <section>
    <h3>Open Source </h3>
    <p>
      <OpenHub account="robertburrelldonkin"/>
    </p>
    <h4>Apache Software Foundation <Outbound url='https://www.apache.org'/></h4>
    <p>rdonkin@apache.org</p>
    <p>Notes - including some information about what the project does,
      and what sort of stuff I worked on.</p>
    <p>

      <h6>Committee Member, Legal Affairs
        Company Name The Apache Software Foundation
        Dates volunteered Mar 2007 – Apr 2010 Volunteer duration 3 yrs 2 mos
        Cause Science and Technology</h6>

      <p>A founding member of the Legal Affairs committee.</p>

      <p>Code provenance and license compatibility may not be the most glamorous subjects but are essential to the
        smooth
        running of an open source foundation. The Apache Software License is permissive, encouraging a rich downstream
        license ecology. This means more legal complexity, paperwork and policy than is typically the case for
        reciprocal
        licenses. Interests in open source release management, open standards, process codification and digital rights
        led
        me to become a regular contributor to the legal-discuss mailing list. Legal Affairs was create to help the
        community develop and document policy and guidance. When the committee formed, I was asked to join.</p>

      <p>My time taught me a lot about diplomacy, perhaps too often the hard way.</p>

      <Experiences
        title='Experiences'
        bullets={[
          { point: "Open source governance" },
          { point: "Process codification" },
          { point: "Consensus building" },
          { point: "Open policy development" },
          { point: "Diplomacy" },
          { point: "Intellectual property for open source" }]}/>
    </p>
    <h5>
      Apache commons
    </h5>
    <p></p>

    <h6>Committee Member, Jakarta PMC
      Company Name The Apache Software Foundation
      Dates volunteered Jan 2003 – Dec 2011 Volunteer duration 9 yrs
      Cause Science and Technology</h6>

    <p>Elected to the project management committee in 2003.</p>

    <p>For a few years in the early '00s, the Jakarta project felt like the epicenter of the exploding Java universe.
      Many influential and popular products (including Ant, Tomcat, Lucene, Struts, Maven, Poi and Commons) were forged
      in this fire. Too much, too fast. It couldn't last, and didn't. I was elected at the start of the effort to
      introduce a more sustainable and representative structure. To allow its contained components to grow and mature,
      the Jakarta project disassembled itself. Quite appropriate for a community wedded to separation of concerns,
      inversion of control and loose coupling. I think this change turned out well.</p>

    <p>Jakarta is now quietly fading away but my memories remain.</p>

    <Experiences
      title='Experiences'
      bullets={[
        { point: "Open source governance" },
        { point: "Diplomacy" },
        { point: "Process codification" },
        { point: "Consensus building" }]}/>


    <h5>Committer
      Company Name The Apache Software Foundation
      Dates volunteered Feb 2001 – Present Volunteer duration 18 yrs 9 mos
      Cause Science and Technology</h5>

    <p>First elected a committer in 2001, for the Jakarta project.</p>

    <p>I've contributed to a variety of products in a range of projects, most notably in the first phase of the (then
      Jakarta) Commons. The Apache Commons project now houses some of the worlds most widely installed open source
      software. The way that this happened interests me. The successful recipe was a broad and open development
      community coding tightly and clearly scoped micro-libraries collaborating through one noisy list. Socially dense
      but process light.</p>

    <p>Open development at Apache was a major part of my life in the '00s.</p>

    <Experiences
      title='Experiences'
      bullets={[
        { point: "Open development philosophy" },
        { point: "Open source software" },
        { point: "Open source release management" },
        { point: "Open standards" },
        { point: "Open API design" },
        { point: "Collaborative documentation" },
        { point: "Java" },
        { point: "XML, XSLT, SAX, DTD, JAXB, XML Schema" },
        { point: "Eclipse" },
        { point: "Ant and Maven" },
        { point: "CVS and Subversion" },
        { point: "Teamwork" },
        { point: "TDD with JUnit" },
        { point: "BDD with JMock" },
        { point: "Cryptography" },
        { point: "OpenPGP" },
        { point: "Design Patterns" },
        { point: "Editing (news)" },
      ]}/>


    <h5>Committee Member, Incubator PMC
      Company Name The Apache Software Foundation
      Dates volunteered May 2006 – Feb 2012 Volunteer duration 5 yrs 10 mos
      Cause Science and Technology</h5>

    <p>Elected to the project management committee in 2006. Resigned in 2012.</p>

    <p>From the outside, the most visible part of bringing a project to Apache is the due diligence - demonstrating code
      provenance, adopting the Apache License and ensuring compliance with license compatibility policies. Some might be
      fooled into thinking that it's also the most important part. But Apache values community over code. Learning,
      adopting and (hopefully) enriching the open development culture here at Apache is essential for graduation.
      Achieving a harmonious balance between these aspects interests me.</p>

    <p>Energy consuming fun.</p>

    <Experiences
      title='Experiences'
      bullets={[
        { point: "Mentoring" },
        { point: "Community building" },
        { point: "Community driven documentation" },
        { point: "Process codification" },
        { point: "Consensus building" },
        { point: "Open source governance" },
        { point: "Code provenance" }]}/>

    <h5>Committee Member, James PMC
      Company Name The Apache Software Foundation
      Dates volunteered Apr 2007 – Present Volunteer duration 12 yrs 7 mos
      Cause Science and Technology</h5>

    <p>Elected to the project management committee in 2007.</p>

    <p>I'm interested in intelligent personal information management - finding smart ways to make sense of the data
      flood
      faced by people. This led me to email, and to look for a modular, programmable open source platform for
      experimentation. James - and it's development community - had potential. I was draw in, far beyond my original
      intentions. We worked hard together to grow our community, and our software. As well as implementing IMAP and
      improving our SMTP, we factored out a loosely coupled component based architecture and rewired James for modern,
      lightweight dependency injection containers. The newly reusable libraries encouraged experts on specific aspects
      to join our community. Kudos to everyone involved.</p>

    <p>I'm yet to reach my original destination but my journey has been worthwhile.</p>

    <Experiences
      title='Experiences'
      bullets={[
        { point: "IMAP" },
        { point: "SIEVE" },
        { point: "MIME parsing (SAX, DOM and pull)" },
        { point: "SMTP" },
        { point: "POP3" },
        { point: "Modularisation" },
        { point: "Refactoring" },
        { point: "Inversion of Control" },
        { point: "Separation of Concerns" },
        { point: "Dependency Injection" },
        { point: "Message driven architecture" },
        { point: "Open standards" },
        { point: "Protocol implementation" },
        { point: "Concurrency" },
        { point: "Community building" },
        { point: "Parser generation with JavaCC" },
        { point: "Domain specific languages" },
        { point: "Cryptography" },
        { point: "SMIME" },
        { point: "OpenPGP" },
        { point: "Spring" },
        { point: "Maven" }]}/>


    <h5>VP, Apache Creadur
      Company Name The Apache Software Foundation
      Dates volunteered Apr 2012 – Apr 2013 Volunteer duration 1 yr 1 mo
      Cause Science and Technology</h5>

    <p>Elected VP, Apache Creadur in April 2012.</p>

    <p>Apache grows software through self-organising teams. Each project is overseen lightly by a management committee
      (a
      PMC, in Apache-speak). Each PMC is chaired by a VP. A good Apache VP isn't concerned with herding cats but with
      facilitating the community's self-directed progress, and leads by example rather than by diktat.</p>

    <p>Apache Creadur develops open source software that helps audit, create and comprehend software distributions, for
      example by generating suitable legal documentation. The Creadur top level project was established in April 2012 as
      a home for the Rat community after graduating from the Apache Incubator. Kudos to everyone who helped to make this
      happen.</p>

    <Experiences
      title='Experiences'
      bullets={[
        { point: "reporting to the board" },
        { point: "Apache Maven plugin development" },
        { point: "Apache Ant task development" },
        { point: "JDOM" },
        { point: "Apache Velocity" },
        { point: "TDD" },
        { point: "BDD" },
      ]}/>

    <h5> Emeritus Member
      Company Name The Apache Software Foundation
      Dates volunteered Jul 2005 – Present Volunteer duration 14 yrs 4 mos
      Cause Science and Technology</h5>

    <p>Elected a Member of The Apache Software Foundation in July 2005. Emeritus from February 2012.</p>

    <p>With over 50 projects, 2500 committers and a top 1000 site, The Apache Software Foundation is a major non-profit
      open source software foundry. Apache HTTPD (the world's more popular web server by volume) was the first (and is
      perhaps the most famous) project but many other Apache projects are well known (including Ant, Axis, Hadoop,
      Harmony, Lucene, Maven and Tomcat). Perhaps less widely understood is that this success arises from an open,
      community led development philosophy and a process-light, socially-dense organisational structure. New members are
      elected by the existing membership on the basis of their contributions to Apache.</p>

    <p>Becoming a Member meant a lot to me.</p>

    <Experiences
      title='Experiences'
      bullets={[
        { point: "Open source governance" },
        { point: "Socially dense, process light organisations" },
        { point: "Community driven documentation" },
        { point: "Process codification" },
        { point: "Consensus building" },
        { point: "Open development" }]}/>

    <pre>
      597C 729B 0237 1932 E77C
      B9D5 EDB8 C082 A6EE 6908
    </pre>
    <section>
      <h5>Older CV</h5>

      The Apache Software Foundation

      Non-Profit; Computer Software industry

      2005 – Present (6 years)

      <p>Elected a Member of The Apache Software Foundation in July 2005.</p>

      <p>Best known for contributions to Jakarta, Commons and James projects, community building and process
        codification
        in the Incubator and documentation at the Foundation level. My interests include community building, release
        security and management, and intellectual property.</p>


      <p>Committer</p>

      <h5>2001 - Present</h5>

      <p>Elected a Jakarta committer in 2001</p>

      <pre>rdonkin Robert Burrell Donkin apsite, commons, incubator, incubator-pmc, infrastructure-interest, jakarta,
        jakarta-pmc, james, james-pmc, kato, member, rat, ws</pre>

      <h5>Incubator PMC from 2006-05-07</h5>

      <h5>Jakarta PMC, date not recorded</h5>

      <h5>James PMC 2007-04-23</h5>

      <h5>Legal Affairs founding member (est. 03/2007) - 2010</h5>

      <p>Open development at Apache was a large part of my life in the '00s.</p>


      <>Interests include and intellectual property. Best known for contributions to Jakarta, Commons and James
        projects,
        community building and process codification in the Incubator and documentation at the Foundation level.
      </>

      <p>I continue to be fasinated by the power of socially dense, process light organisations.</p>

      <p>The Apache Software Foundation is a 501(c)3 non-profit organisation dedicated to the creation of open source
        software with an open community based development philosophy[1]. New members are elected by the existing
        membership in recognition of their contributions to the foundation. Becoming a member meant a lot to me.</p>

      <p>The most famous Apache product is HTTPD. In 2009, it become the first web server to surpass the 100 million
        site
        milestone. Since April 1996 Apache has been the most popular HTTP server software in use and at the start of
        2011,
        Apache served over 59.13% of all websites and over 66.62% of the million busiest.[4] Less visibly, Apache
        provides
        open library, framework and server infrastructure for the Java platform including Tomcat, Xerces, Maven, Ant,
        Commons, Struts, Wicket and Axis. The foundation now has over 50 projects, 3000 committers and a top 1000 web
        site.</p>

      [1] http://www.apache.org/foundation/how-it-works.html
      [2] http://news.netcraft.com/archives/2009/02/18/february_2009_web_server_survey.html
      [3] http://news.netcraft.com/archives/2011/01/12/january-2011-web-server-survey-4.html


      <h5>Committee Member, Incubator PMC</h5>
      <p>
        I loved process codification. I've experienced the Incubator from all sides - as a mentor, as a champion and as
        a
        . I've made plenty of mistakes but think I've learnt from them. learnt a lot about community building.
      </p>

      <h6>Committee Member, Legal Affairs</h6>


      <p>The way that licensing influences successful organisational structures for free and open source interests
        me.</p>

      <p>The ecosystem of derivative works is homogeneous for reciprical licenses - such as the GPL. This encourages
        easy
        and safe patch swapping, and is associated with peer-to-peer distributed structures. Permissive licenses - such
        as
        the Apache License - seem to favour canonical structure, with brand and trademark used to indicate the qualities
        of particular variants.</p>

      <p>My role has not always been uncontroversial, but I think I've learnt to mix more in more diplomacy.</p>

      <p>Checking the licensing and legal side of a release is a tedious but necessary duty.</p>

      <p>At Apache verification is made more difficult by policy</p>

      <p>Legal affairs was formed to coordinate the codification of</p>

      <p>I resigned in 2010 when it had become clear that my studies were consuming all my time.
        Committee Member, Jakarta PMC</p>

      <p>Helped sub-projects to understand the advantages of a flat organisational structure.</p>

      <p>For a brief period in the early '00s, the Jakarta project felt like the epicenter of the exploding Java
        universe.
        This was a really exciting time in my life, and forged a lot of my beliefs about collective development. Ant,
        Tomcat, Lucene, Struts, Maven, Poi and the Commons libraries all emerged from this caldron. Too much, too fast:
        it
        could never last, and didn't. My main efforts on the Jakarta PMC were evangelising the flatter organisational
        structure which Apache Software Foundation was moving to. To allow the parts to grow and mature, Jakarta was
        broken up.</p>


      <h5>Committee Member, James PMC</h5>

      <p>An A passion for intelligent personal information management feed lead me to look around for a modular,
        programmable platform for email and more. Ended up developing IMAP, factoring out a loosely coupled component
        based architecture and working on parsing. Along the way I got to learn a lot from some cool technologists.</p>

      <p>My quest to fix email ended with a realisation that existing RESTful protocols such as Atom are more than
        adequate
        replacements. Like so much in life, it turned out that it was the journey that made it worthwhile and the
        friends
        that I made on road.</p>

      <p>James is a Java mail server. The project supplies components</p>

      <p>Elected a member of the project management committee for James in 2007.</p>

      <p>In the mid '00s, I developed an interest in intelligent personal information management - finding smart ways to
        make sense of the flood of data faced by most people in this information age. I wanted to find a modular,
        programmable open source platform for experimentation. This led me to the James server.</p>

      <p>James was the first time I worked on implementation of open protocol standards. I ended up helping to develop a
        (working) IMAP implementation and factoring out and retooling libraries suitable for dependency injection with
        modern, lightweight containers (such as OSGi and Spring).</p>

      <Experiences
        bullets={[
          { point: "IMAP" },
          { point: "SIEVE" },
          { point: "MIME parsing (SAX, DOM and pull models)" },
          { point: "SMTP" },
          { point: "POP3" },
          { point: "Modularisation" },
          { point: "Refactoring" },
          { point: "Inversion of Control" },
          { point: "Separation of Concerns" },
          { point: "Message driven architectures" },
          { point: "Open standards" },
          { point: "Protocol implementation" },
          { point: "Concurrency" },
        ]}
        title='Experiences'/>

      <h5>Committee Member, Commons PMC</h5>

      <p>The Apache Commons project is home to some of the world's most widely installed open source software. The way
        that this happens interests me. The successful recipe combined a broad and open development community coding
        tightly and clearly scoped micro-libraries. Development happened on one noisy list with collective
        responsibility for quality.</p>

      <p>Like many of the friends I made in the early days, I drifted towards other interests - personal information
        management, machine learning and speech recognition. In the end, this left me with too little free energy to
        perform effective oversight in the Commons but I look back with affection on those days and this project. I'm
        particularly proud of the contributors I helped with their first steps.</p>

      <p>Jakarta Jan 2003</p>

      <p>http://www.apache.org/foundation/records/minutes/2003/board_minutes_2003_01_22.txt</p>

    </section>
  </section>
)

export default OpenSource
