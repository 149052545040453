import React from "react"

const Events = () => (
  <section>
    <h4>Events</h4>
    <h5>Apache</h5>
    <p>The Apache Software organises https://www.apachecon.com/</p>
    <p>Happy Birthday Apache!</p>
    <ul>
      <li>ApacheCon 2019 NA (highlights...?)</li>
      <li>ApacheCon EU 2009
        Amsterdam, Netherlands http://archive.apachecon.com/c/aceu2009/
      </li>
      <li>ApacheCon EU 2008
        Amsterdam, Netherlands http://archive.apachecon.com/eu2008
      </li>
      <li>ApacheCon EU 2007
        Amsterdam, Netherlands http://archive.apachecon.com/eu2007/
      </li>
      <li>ApacheCon EU 2006
        Dublin, Ireland http://archive.apachecon.com/2006/EU/
      </li>
      <li>ApacheCon EU 2005
        Stuttgart, Germany http://archive.apachecon.com/2005/EU/
      </li>

    </ul>
    <p>Bar camps - Apache Bar Camp...?</p>
    <h5>BCS</h5>
    <ul>
      <li>SPA</li>
      <li>Turin lectures</li>
    </ul>
    <h5>Academia</h5>
    <p>Protege, give some info</p>
    <p>University of Manchester</p>
    <h5>Bar Camps and Unconferences</h5>
    <p>What's a bar camp, what's an unconference</p>
    <p>Oxford...? Manchester...? Do I have lists of attendence...?</p>
    <h5>Education Camp Oxford</h5>
    <p>Dr Chuck</p>
  </section>
)

export default Events