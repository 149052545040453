import React from "react"
import PropTypes from "prop-types"
import "./experiences.css"

class Experiences extends React.PureComponent {
  render() {
    const { title, bullets } = this.props
    return (
      <section>
        <h6 style={{ fontWeight: "normal", color: "dimgrey", marginBottom: "0.33rem" }}>{title}</h6>
        <ul className='experiences'>
          {bullets.map((bullet) => <li>{bullet.point}</li>)}
        </ul>
      </section>
    )
  }
}

Experiences.propTypes = {
  bullets: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default Experiences

