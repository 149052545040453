import React from "react"
import Group from "./group"
import AgileYorkshire from "./tech/agile_yorkshire"
// TODO: Edit and present
//
// Speaker
// Company Name Hack to the Future
// Dates volunteered Feb 2012 Volunteer duration 1 mo
// Cause Science and Technology
//
// Kudos to @teknoteacher for making such a fantastic event happen
//
// Thanks to the sponsors and keynoters - the Beeb, Microsoft open source gadgeteers, @DrTomCrick, Android swag from Google and everyone else
//
// A big shout out to all the inspirational, enthusiastic and clueful teachers
//
// And most importantly to the hundreds of kids and their parents with the courage to dream
//
// Watch out Silicon Valley, here comes Preston
//
// Read more at
// * http://lanyrd.com/2012/hacktothefuture/
// * http://www.jroller.com/robertburrelldonkin/entry/hack_to_the_futur
//
//
//   Attendee and Speaker
// Company Name Bradford GNU/Linux Users Group
// Dates volunteered Feb 2009 – Present Volunteer duration 10 yrs 9 mos
// Cause Science and Technology
//
// A great local technology group.
//
//   I ran a series of short talks on open source licensing
//
// http://opentalkware.googlecode.com/svn-history/r180/talk/OpenSourceIP/trunk/index.html
//
//
//   Attendee and Speaker
// Company Name Agile Yorkshire
// Dates volunteered 2007 – Present Volunteer duration 12 yrs
// Cause Science and Technology
//
// Agile Yorkshire is a great local technology community.
//
//   I've spoken on Test Doubles, paired with Mark van Harmelen to deliver a hands on workshop on retrospectives and given short talks on facilitation, timeboxing and The Pomodoro Technique.
//
//
// XP MAnchester...?
//
//   BarCamps...?

const Communities = () => (
  <section>
    <p>
      TODO: Edit
    </p>
    <h3>Tech Communities</h3>
    <p>Great to see the explosion of technology communities across the North of
      England and the wealth of opportunities available now. I contributed back
      when the scene was just getting going. It's a particular pleasure to
      bump into people from those times and see how well they've done.</p>
    {
      // XP MAnchester...? JavaScript Leeds...? Open Coffee...? // BarCamps...?
      // Unconferences ...?
      // Free Software manchester ...?
      // MadLabs manchester ...?
    }
    <AgileYorkshire/>
    <Group name='Bradford GNU/Linux Users Group' when='2009 onwards' role='Attendee and Speaker'>
      {
        //
        // Attendee and Speaker
        // Company Name
        // Dates volunteered Feb 2009 – Present Volunteer duration 10 yrs 9 mos
        // Cause Science and Technology
        //
        // A great local technology group.
        //
        // I ran a series of short talks on open source licensing
        //
        // http://opentalkware.googlecode.com/svn-history/r180/talk/OpenSourceIP/trunk/index.html
        //
        //
      }
    </Group>
    <Group name="Hack to the Future" when="2012" role="Attendee and Speaker">
      {
        // Speaker
        // Company Name
        // Dates volunteered Feb 2012 Volunteer duration 1 mo
        // Cause Science and Technology
        //
        // Kudos to @teknoteacher for making such a fantastic event happen
        //
        // Thanks to the sponsors and keynoters - the Beeb, Microsoft open source gadgeteers, @DrTomCrick, Android swag
        // from Google and everyone else
        //
        // A big shout out to all the inspirational, enthusiastic and clueful teachers
        //
        // And most importantly to the hundreds of kids and their parents with the courage to dream
        //
        // Watch out Silicon Valley, here comes Preston
        //
        // Read more at
        // * http://lanyrd.com/2012/hacktothefuture/
        // * http://www.jroller.com/robertburrelldonkin/entry/hack_to_the_futur
        //
      }
    </Group>

  </section>
)
export default Communities
