import React from "react"
import Days from "./days"
import Courses from "./courses"

const Freelance = () => (
  <section>
    <h4>Freelance</h4>
    <Days/>
    <Courses/>
  </section>

)

export default Freelance