import React from "react"
import PropTypes from "prop-types"
import Outbound from "./outbound"

const Group = ({ name, children, when, role, url }) => (
  <section>
    <h4 style={{ marginBottom: "0.25rem", marginTop: "2rem" }}>
      {when ? `${when} \u2015 ` : ""}
      {name} {url ? <Outbound url={url}/> : ""}
      <span style={{ color: "dimgrey", margin: "1em", fontSize: "smaller" }}>{role}</span>
    </h4>
    {children}
  </section>
)


Group.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  when: PropTypes.string,
  url: PropTypes.string,
}

export default Group
