import React from "react"
import Group from "../group"
// TODO: Edit and present
//
//   Attendee and Speaker
// Company Name Agile Yorkshire
// Dates volunteered 2007 – Present Volunteer duration 12 yrs
// Cause Science and Technology
//
// Agile Yorkshire is a great local technology community.
//
//   I've spoken on Test Doubles, paired with Mark van Harmelen to deliver a hands on workshop on retrospectives and given short talks on facilitation, timeboxing and The Pomodoro Technique.
//
//

const AgileYorkshire = () => (
  <section>
    <Group name='Agile Yorkshire' role='Attendee and Speaker' when='2007 onwards' url='http://www.agileyorkshire.org/'>
      {
        //TODO: title link...?
        // Attendee and Speaker
        // Company Name Agile Yorkshire
        // Dates volunteered 2007 – Present Volunteer duration 12 yrs
        // Cause Science and Technology
        //
        //
        //

      }
      <p>Agile Yorkshire is a great local technology community.</p>
      <p>
        The Leeds tech community has blossomed and bloomed with lots and lots (TODO: links)
        but back in the early 00s, Agile Yorkshire was one of the first communities to gather together
        to share our passion, visions, dreams and experiences. I gave a talk on tests developers write,
        title test doubles and taken from BOOK LINK as well as my experiences both open source (Apache Jakarta)
        and commercial (Retail Express). The back room of the pub (which pub) was packed to the rafters. Not sure why,
        probably the timing, but seemed to help a lot of developers and managers make the leap to test driven
        development
        and test first. More than one person in the years since has made an effort to seek me out and thank me for the
        positive impact that talk had.
      </p>
      <p>
        Agile Yorkshire has gone through many phases, with different venues, helpers and organisers. The numbers
        attending
        have gone up and down as groups break off to start their own communities. I lent a hand
        from time to time but it's great that Royd has done so much to take the community onwards.
      </p>
      <p>
        I prefer hands on, experiencial sessions. This worked really well when Agile Yorkshire was in Old Broadcasting
        House with space to spread out and split into groups. Good days. Royd has done a great job leading the
        community.
        As the Agile scene has matured, as people have grown, as the ideas no longer seem novel adn radical it's great
        to see that Royd has matured the group to more expert practioner led stuff sharing experiences of coaching large
        groups.
      </p>
      <p>
        I've spoken on Test Doubles, paired with Mark van Harmelen to deliver a hands on workshop on
        retrospectives and
        given short talks on facilitation, timeboxing and The Pomodoro Technique.
      </p>
      <p>TODO: dig up talks and add into slides</p>
    </Group>
  </section>
)
export default AgileYorkshire
