import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OpenSource from "../components/open_source"
import Communities from "../components/communities"
import Standards from "../components/standards"
import Media from "../components/media"
import Freelance from "../components/freelancer/freelance"
import Events from "../components/events"

// TODO:                  Should this be contributions...?

const ContributionsPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <Events/>
    <Freelance/>
    <OpenSource/>
    <Communities/>
    <Standards/>
    <Media/>
  </Layout>
)

export default ContributionsPage
