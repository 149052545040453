import React from "react"
import ReactPlayer from "react-player"
import Courses from "./freelancer/courses"

const Media = () => (
  <section>
    <h4>Media</h4>
    <section>
      <h5>Sessions and Slides ??? </h5>
      <p>
        I've done quite a few talks over the years, both for employers and for pleasure.
      </p>
      <p>
        https://www.slideshare.net/robertburrelldonkin
        TODO: Update details
        Consider speaker deck
      </p>

      <section><h6>Concurrency Series, William Hill</h6>
        <p>TODO: Find some way to embed, perhaps upload and link I think</p>
      </section>
      <p>Also agile sessions, including early talk on Hadoop and TDD, BDD</p>

    </section>
    <section>
      <h5>Blogs <small>200?-201?</small></h5>
      <p>
        Way back when, I used to blog. I used to blog quite a lot. A started blogging for myself, notes about technology
        that some future self then add more personal stuff. Even includes some visual entries from my days recovering.
      </p><p>
      You can take the boy out of the city but the 2-tone roots never quite wash away. I like to think of the two
      blogs as Black and White, based on their background colour. Black was started later on blogger and features shiny
      multimedia. White was hosted on the insanely popular JRoller. Sadly, all too popular and too expensive.
      Gone but not forgotten.
    </p><p>
      The White blog "Weirdest Undreampt Use Case" was syndicated quite widely for a time, via Planet Apache
      https://blogs.apache.org/planet/ gone now, and http://planet.intertwingly.net/. It's more open source.
      It's also more edgy, little with odd little poems to a burning city and friends dead or raped,
      lost from my life. Working girls, drive my shootings saved by mad cricket skills and days when <i>Jihad</i>
      still meant the struggle to build a better world.
      Anger, pain and loss but also hope and dreams. Dirty old Bradford.
      Rest in peace now, whereever and whenever. Pub closed. Churches and chapels that are now Mosques and Madrassahs.
    </p><p>
      "Weirdest Undreampt Use Case" is Torsten quote from Dublin. If I recall correctly, we were talking automated
      testing for parsers over a Guiness or two.
    </p><p>
    </p><p>
      Sam Ruby is one my heros. http://planet.intertwingly.net/ is still worth reading.
    </p><p>
      I have ambitions to get hold of the data and get up a site for historuic purposes.
    </p>

    </section>
    <Courses/>
    <section>
      <h5>Agile Shorts <small>2010-2011</small></h5>
      <p>My main focus in 2010 and 2011 was the rest and exercises needed to recover from the injury.</p>
      <p>
        Inspired by my experiences teaching agile techniques and methods to graduate students, I cut some
        short, unedited, straight to camera videos. These were raw and seem underproduced today. The software in phones
        those days didn't cope well with outdoors sounds and the picture quality isn't great.
      </p><p>
      But I wanted them to be authentic, unedited and unenhanced - a message that you don't need great connections, to
      live in a shiny part of a big city to be a software developer. We've cleaned up and bought suits but we came from
      the streets, dales and valleys. People all over the world, with all sorts of backgrounds worked together to lay
      the foundations.
    </p><p>
      There had positive feedback and there was some interest around sponsorship and so on but I'd already been bruised
      by HMRC after taking on a technical review from a US firm. I ended up paying more in tax than the gig was worth
      as well as endless arguments about tax law with local officials. More importantly, . I'm not sure how many people
      appreciate how
    </p><p>
      At the time, these videos were edgy and innovative, reaching out to the streets. A lot of people at the time were
      looking to bring technology to the masses, to empower them, all over the world. A younger me. These look dated
      but I prefer to leave them as they are, a statement about those times and myself in them.
    </p>

      <p>https://www.youtube.com/channel/UCwkJVwrnZY_rwHV7a6tozYw</p>
      <section>
        <h6>Catalog</h6>
        <p>TODO: Catalog</p>
        <ReactPlayer url='https://www.youtube.com/watch?v=_ssbhJpJjWo' playing/>
      </section>
    </section>
  </section>

)

export default Media