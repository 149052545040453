import React from "react"

const Courses = () => (
  <section>
    <h5>Freelance Courses 2016</h5>
    <p>
      I spent the first half of 2016 considering my next move. One of the side projects I pursued at the time
      was creating courses. I struggled with the sound quality and editing. It's a lot of work.
    </p><p>
    I've had plenty of positive feedback from the improvisational deliver stuff, whether agile shorts, sessions
    at Agile Yorkshire, delivery Agile . But a course needs an investment in organisation. I was also never able to
    get the sound quality I was looking for. I live Saltaire but it's an industrial landscape. Planes fly over,
    good trains rattle by or stop at the signal. I struggled to cut out the noise and stopping the record.
  </p><p>
    I have decided to upload my videos to youtube in the hope that people may learn something.
  </p>
  </section>

)

export default Courses