import React from "react"

const Days = () => (
  <section>
    <h5>Charity Days 2012</h5>
    <p>
      Back in 2012, to aid my transition back into work and to raise some money from charity,
      I offered some freelance charity days. The idea is that I'd give a day of
      consultancy or training in return for a donation to charity. This proved
      quite interested, and even though I didn't advertise widely it a few people took up
      my offer.
    </p>
    <p>
      I blogged my experiences on https://itstechupnorth.blogspot.com/
      TODO: pick out and deep link
    </p><p>
    I enjoyed coaching and training. Becoming an agile coach or trainer might have
    been one path my life took back then. The time was right here in England.
  </p><p>
    Today, we're pretty much state of the art with lots of people deep agile and lean.
    Qualifications and all that.
  </p><p>
    One day, I'll export this blog and host it somewhere more permanent.

  </p>

  </section>

)

export default Days