import React from "react"
import PropTypes from "prop-types"
import "./open_hub.css"

const accountBaseUrl = (account) => {
  return `https://www.openhub.net/accounts/${account}`
}

const OpenHub = ({ account }) => (
  <span className='openhub'>
    <a href={accountBaseUrl(account)} target="_blank" rel="noopener noreferrer">
      <img
        alt={`Open Hub profile summary for account ${account}`}
        src={`${accountBaseUrl(account)}/widgets/account_detailed?format=gif`}
      />
    </a>
  </span>
)

OpenHub.propTypes = {
  account: PropTypes.string,
}

export default OpenHub

